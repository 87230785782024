<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card flat height="auto">
          <v-card-text class="pa-0">
            <v-toolbar flat color="primary">
              <v-toolbar-title>
                <v-icon color="white">{{entity.header_menu.icon}}</v-icon>

                <span class="font-25 white--text px-3">{{ $ml.get(entity.header_menu.name) }}</span>
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn
                v-if="entity.component_new_route"
                color="primary"
                dark depressed
                class="mb-2 lowercase"
                :ripple="false"
                :to="{path: entity.component_new_route}"
              >{{ $ml.get('general_new') }} {{ $ml.get(entity.header_menu.name).slice(0, -1) }}</v-btn>

              <v-dialog v-else v-model="dialog" max-width="1200px" persistent>
                <v-btn
                  slot="activator"
                  color="primary"
                  dark depressed
                  class="mb-2 lowercase"
                  :ripple="false"
                >{{ $ml.get('general_new') }} {{ $ml.get(entity.header_menu.name).slice(0, -1) }}</v-btn>

                <v-card>
                  <v-card-title class="primary">
                    <span class="headline white--text">{{ formTitle }}</span>

                    <v-spacer></v-spacer>

                    <v-btn icon @click="close">
                      <v-icon color="white">close</v-icon>
                    </v-btn>
                  </v-card-title>

                  <v-card-text>
                    <v-form v-model="validItem" ref="validItem">
                      <template v-for="(section, index) in entity.sections">
                        <v-layout row wrap :key="index">
                          <v-flex xs12 class="px-2">
                            <p class="font-18 mb-0">{{ $ml.get(section.title) }}</p>
                          </v-flex>

                          <template v-if="dialog">
                            <template v-for="(attribute, key) in entity.attributes">
                              <v-flex
                                class="px-2"
                                :key="key"
                                v-if="section.attributes.includes(key)"
                                :class="[{'xs12 sm6 md4': !section.grid}, section.grid]"
                              >
                                <dynamic-field :options="attribute" :key="key + index" :value="editedItem[key]" @update:value="setValue($event, key)" :editing="editedIndex" />
                              </v-flex>
                            </template>
                          </template>

                          <v-flex xs12 class="px-2" v-if="index < entity.sections.length - 1">
                            <v-divider class="my-4"></v-divider>
                          </v-flex>
                          <v-flex xs12 class="px-2 mb-4" v-else>
                          </v-flex>
                        </v-layout>
                      </template>
                    </v-form>
                  </v-card-text>

                  <v-card-actions class="primary">
                    <v-spacer></v-spacer>

                    <v-btn
                      color="primary"
                      dark depressed
                      class="ma-0 lowercase"
                      :ripple="false"
                      :disabled="!validItem"
                      @click.native="save"
                    >{{ $ml.get('general_save') }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$ml.get('general_search')"
                single-line
                hide-details
                solo
                class="hidden-shadow max-width-400 small-input"
              ></v-text-field>
            </v-toolbar>

            <v-data-table
              class="px-3"
              :headers="headers"
              :items="items"
              :loading="g_isLoading"
              :search="search"
              :pagination.sync="pagination"
              :rows-per-page-text="$ml.get('general_rows_per_page')"
              :rows-per-page-items="rowsPerPageItems"
            >
              <v-progress-linear slot="progress" color="primary" indeterminate></v-progress-linear>
              <template slot="items" slot-scope="props">
                <template v-for="(attribute, key) in entity.attributes">
                  <td :key="key" v-if="!attribute.header_hide">
                    <span v-if="attribute.filter === 'date'"> {{ props.item[key] | moment('DD/MM/YYYY') }}</span>
                    <span v-else-if="attribute.custom_filter"> {{ $ml.get(entity.filters[attribute.custom_filter][props.item[key]]) }}</span>
                    <span v-else>{{ attribute.items_url ? (props.item[key] ? props.item[key][attribute.item_text] : '') : props.item[key] }}</span>
                  </td>
                </template>

                <td class="justify-center layout px-0">
                  <template v-for="(action, index) in entity.actions">
                    <v-tooltip top :key="index">
                      <v-btn
                        slot="activator"
                        small icon
                        :color="action.color"
                        depressed
                        :disabled="entity.disable_actions ? entity.disable_actions.edit : false"
                        @click="dynamicFunction(props.item, action.function)"
                        class="mr-2"
                      >
                        <v-icon small>{{ action.icon }}</v-icon>
                      </v-btn>

                      <span>{{$ml.get(action.tooltip)}}</span>
                    </v-tooltip>
                  </template>

                  <v-tooltip top>
                    <v-btn
                      slot="activator"
                      small icon
                      color="success"
                      depressed
                      :disabled="entity.disable_actions ? entity.disable_actions.edit : false"
                      @click="editItem(props.item)"
                      class="mr-2"
                    >
                      <v-icon small>edit</v-icon>
                    </v-btn>

                    <span>{{$ml.get('general_edit')}}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <v-btn
                      slot="activator"
                      small icon
                      color="error"
                      depressed
                      :disabled="entity.disable_actions ? entity.disable_actions.delete : false"
                      @click="deleteItem(props.item)"
                    >
                      <v-icon small>delete</v-icon>
                    </v-btn>

                    <span>{{$ml.get('general_delete')}}</span>
                  </v-tooltip>
                </td>
              </template>

              <template slot="no-data">
                <p class="text-center mb-0">{{$ml.get('general_no_data')}}</p>
              </template>

              <template slot="no-results">
                <p class="text-center mb-0">{{$ml.with(search).get('general_no_results')}}</p>
              </template>

              <template slot="pageText" slot-scope="props">
                {{ props.pageStart }} - {{ props.pageStop }} {{$ml.get('general_of_total')}} {{ props.itemsLength }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <message-dialog :dialog="g_dialog"></message-dialog>
  </v-container>
</template>

<script>
import CRUD from '../CRUD'

export default {
  name: 'DynamicCRUD',
  data () {
    return {
      entity: CRUD.find(c => '/' + c.entity === this.$route.path),
      items: [],
      editedItem: {},
      editedIndex: false,
      defaultItem: {},
      search: '',
      dialog: false,
      validItem: false,
      rowsPerPageItems: [5, 10, 25, { text: this.$ml.get('general_all_rows'), value: -1 }],
      pagination: {
        rowsPerPage: 25
      }
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === false ? (this.$ml.get('general_new') + ' ' + this.$ml.get(this.entity.header_menu.name).slice(0, -1)) : (this.$ml.get('general_edit') + ' ' + this.$ml.get(this.entity.header_menu.name).slice(0, -1))
    },
    headers () {
      let headers = []

      Object.keys(this.entity.attributes).map(key => {
        if (this.entity.attributes[key].header_hide) {
          return
        }

        headers.push({
          text: this.$ml.get(this.entity.attributes[key].label),
          value: key
        })
      })

      headers.push({
        text: this.$ml.get('general_actions'),
        value: 'id',
        sortable: false
      })

      return headers
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  methods: {
    // CRUD
    getItems () {
      this.g_isLoading = true
      this.$api['list' + this.capitalize(this.entity.entity) + 's'](
        response => {
          this.g_isLoading = false
          this.items = response.body.data
        },
        response => this.$errorHandling(response).then(() => this.getItems())
      )
    },
    save () {
      this.g_isLoading = true
      if (this.editedIndex) {
        this.$api['update' + this.capitalize(this.entity.entity)](
          this.editedIndex,
          this.editedItem,
          () => {
            this.close()
            this.getItems()
          },
          response => this.$errorHandling(response).then(() => this.save())
        )
      } else {
        this.$api['create' + this.capitalize(this.entity.entity)](
          this.editedItem,
          () => {
            this.close()
            this.getItems()
          },
          response => this.$errorHandling(response).then(() => this.save())
        )
      }
    },
    delete () {
      this.g_isLoading = true
      this.$api['delete' + this.capitalize(this.entity.entity)](
        this.editedIndex,
        () => {
          this.close()
          this.getItems()
        },
        response => this.$errorHandling(response).then(() => this.delete())
      )
    },
    dynamicFunction (item, func) {
      this.g_isLoading = true
      this.$api[func](
        item.uuid,
        () => {
          this.getItems()
        },
        response => this.$errorHandling(response).then(() => this.dynamicFunction(item, func))
      )
    },
    // Helping methods
    setValue (value, key) {
      this.editedItem[key] = value
      this.$forceUpdate()
    },
    editItem (item) {
      if (this.entity.component_edit_route) {
        this.$router.push(this.entity.component_edit_route + item.uuid)
      }

      this.setupPlace = true
      this.editedIndex = item.uuid
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {
      this.editedIndex = item.uuid
      this.g_dialog = {
        show: true,
        title: this.$ml.get('general_dialog_confirm'),
        message: this.$ml.get('general_dialog_confirm_delete'),
        action: this.delete,
        hasAction: true
      }
    },
    capitalize (string) {
      return string[0].toUpperCase() + string.slice(1)
    },
    close () {
      this.dialog = false
      this.g_dialog.show = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.$refs.validItem.reset()
        this.editedIndex = false
      })
    }
  },
  mounted () {
    this.getItems()
  }
}
</script>
